import React from "react";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import { StaticImage } from "gatsby-plugin-image"
import Section from "../../components/Section";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Headline from "../../components/Headline";
import VimeoEmbed from "../../components/VimeoEmbed";
import Scroller from "../../components/Scroller";

export default class WLS2019 extends React.Component {
  constructor(props) {
    super(props)
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)
  }
  
  render() {
    return ( 
      <Layout transparentNav={true}>
        <Seo title="Live Free - WLS 2019" />
        <Section className="wls2019TopSection" useContainer={false}>
          <StaticImage src="../../assets/img/events/wls-2019/theme.jpg" alt="Life Free"></StaticImage>
        </Section>
        <Section className="wls2019SecondSection" centerVertically={true} useCol={false}>
          <Col xs={6} md={6} lg={5} xl={5}>
            Answers start with the Bible, the Creator God's Roadmap to Real Freedom.
            He's changed a lot of lives with love and power no one else can offer.
            So come! Be part of a week you'll never forget.
            It's time to LIVE FREE!
          </Col>
          <Col xs={5} md={5} lg={4} xl={4}>
            <StaticImage src="../../assets/img/events/wls-2019/WLS-Logo-White.webp" alt="Warrior Leadership Summit"></StaticImage>
          </Col>          
        </Section>
        <Section className="wls2019VideoSection white">
          <Container id="sessions">
            <Row className="justify-content-center text-center">
              <Col xs={12} md={10}>
                <Headline center={true}>WLS Highlights</Headline>
              </Col>
            </Row>
            <Row className="py-5 align-items-center text-center">
              <Col xs={12} md={4}><VimeoEmbed vimeoId="390841607" title="Day 1" /><h3 className="pt-1">DAY 1</h3></Col>
              <Col xs={12} md={4}><VimeoEmbed vimeoId="390841001" title="Day 2" /><h3 className="pt-1">DAY 2</h3></Col>
              <Col xs={12} md={4}><VimeoEmbed vimeoId="390840528" title="Day 3" /><h3 className="pt-1">DAY 3</h3></Col>
            </Row>
            <Row className="justify-content-center text-center">
              <Col xs={12} md={4}><VimeoEmbed vimeoId="390840046" title="Day 4" /><h3 className="pt-1">DAY 4</h3></Col>
              <Col xs={12} md={4}><VimeoEmbed vimeoId="390839446" title="WLS 2019" /><h3 className="pt-1">WLS 2019</h3></Col>
            </Row>
          </Container>
        </Section>
        <Section className="wls2019SpeakersSection" useContainer={false}>
          <StaticImage src="../../assets/img/events/wls-2019/speakers.jpg" alt="Speakers"></StaticImage>
        </Section>
      </Layout>
    );
  }
}
